<template>
  <DialogProducts/>
</template>
<script>
/* eslint-disable */
import DialogProducts from '@/components/DialogProducts.vue'
export default {
   components:{ 
      DialogProducts
   },
  mounted() {
    // this.$swal('Hello Vue world!!!');
  }
}
</script>
