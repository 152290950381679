<template>
    <SendInvoice
    v-if="data.id"
      :lead='data'
    />
</template>

<script>
import SendInvoice from '@/components/SendInvoice.vue'
export default {
  components: { 
    SendInvoice
  },
  data() {
    return {
      data:{},
    }
  },
  async mounted() {
    try{
      let { data } = await this.axios.get(`backoffice/lead/${this.$route.params.id}`)
      this.data = data.data
      // this.$notify({ title:"Message",type: "success", text: "User added successfully" });
    }catch(e) {
      console.log('tere was an errr')
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: message });
    }
  },
}
</script>
