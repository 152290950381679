<template>
 <form class="w-full mx-auto px-20">
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
        Company Name
      </label>
      <input v-model="companyname" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500':v$.companyname.$errors.length}" id="grid-first-name" type="text" placeholder="Company Name">
      <div class="input-errors mt-2" v-for="error of v$.companyname.$errors" :key="error.$uid">
          <p v-if="v$.companyname.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
      </div>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        Fullname
      </label>
      <input v-model="fullname" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500':v$.fullname.$errors.length}" id="grid-last-name" type="text" placeholder="Full Name">
      <div class="input-errors mt-2" v-for="error of v$.fullname.$errors" :key="error.$uid">
          <p v-if="v$.fullname.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
      </div>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-2">
  <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Country
      </label>
      <div class="relative">
        <select v-model="country"  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
          <option v-for="location in locations" :key="location.code" :value="location">{{location.country}}</option>
        </select>
      </div>
    </div>
    

    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
        Phonenumber
      </label>
      <div class="flex">
        <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
          {{country.calling_codes}}
        </span>
        <input v-model="phonenumber" class=" ml-2 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500':v$.phonenumber.$errors.length}" id="grid-city" type="text" placeholder="Phonenumber">
      </div>
       <div class="input-errors mt-2" v-for="error of v$.phonenumber.$errors" :key="error.$uid">
          <p v-if="v$.phonenumber.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
      </div>
    </div>
    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
        E-mail
      </label>
      <input v-model="email" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500':v$.email.$errors.length}" id="grid-city" type="text" placeholder="E-mail">
      <div class="input-errors mt-2" v-for="error of v$.email.$errors" :key="error.$uid">
          <p v-if="v$.email.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
      </div>
    </div>
    <!-- <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        User Role
      </label>
      <div class="relative">
        <select v-model="mutUserType" disabled class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
          <option value="HUNTER">HUNTER</option>
          <option value="BACKOFFICE">BACKOFFICE</option>
          
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div> -->
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        City
      </label>
      <input v-model="city" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500':v$.city.$errors.length}" id="grid-last-name" type="text" placeholder="City">
      <div class="input-errors mt-2" v-for="error of v$.city.$errors" :key="error.$uid">
          <p v-if="v$.city.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
      </div>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        State
      </label>
      <input v-model="state" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500':v$.state.$errors.length}" id="grid-last-name" type="text" placeholder="State">
      <div class="input-errors mt-2" v-for="error of v$.state.$errors" :key="error.$uid">
          <p v-if="v$.state.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
      </div>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
        Zip Code
      </label>
      <input v-model="zipCode" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500':v$.zipCode.$errors.length}" id="grid-first-name" type="text" placeholder="Zip code">
      <div class="input-errors mt-2" v-for="error of v$.zipCode.$errors" :key="error.$uid">
          <p v-if="v$.zipCode.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
      </div>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        Skype
      </label>
      <input v-model="skype" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500':v$.skype.$errors.length}" id="grid-last-name" type="text" placeholder="Skype">
      <div class="input-errors mt-2" v-for="error of v$.skype.$errors" :key="error.$uid">
          <p v-if="v$.skype.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
      </div>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full  px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
        Address
      </label>
      <textarea v-model="address" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500':v$.address.$errors.length}" id="grid-first-name" type="text" placeholder="Address"></textarea>
      <div class="input-errors mt-2" v-for="error of v$.address.$errors" :key="error.$uid">
          <p v-if="v$.address.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
      </div>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full  px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
        Call Comments
      </label>
      <textarea v-model="comments" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500':v$.comments.$errors.length}" id="grid-first-name" type="text" placeholder="Comments"></textarea>
      <div class="input-errors mt-2" v-for="error of v$.comments.$errors" :key="error.$uid">
          <p v-if="v$.comments.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
      </div>
    </div>
  </div>
   <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2  px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
        Services
      </label>
     <ul class="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
              <div class="flex items-center pl-3">
                  <input v-model="services" id="vue-checkbox" type="checkbox" value="webdev" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  <label for="vue-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Web Development</label>
              </div>
          </li>
          <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
              <div class="flex items-center pl-3">
                  <input v-model="services" id="react-checkbox" type="checkbox" value="trans" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  <label for="react-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Translations</label>
              </div>
          </li>
          <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
              <div class="flex items-center pl-3">
                  <input v-model="services" id="react-checkbox" type="checkbox" value="seo" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  <label for="react-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Search Engine Optimization</label>
              </div>
          </li>
          <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
              <div class="flex items-center pl-3">
                  <input v-model="services" id="angular-checkbox" type="checkbox" value="appdev" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  <label for="angular-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">App Development</label>
              </div>
          </li>
          <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
              <div class="flex items-center pl-3">
                  <input v-model="services" id="laravel-checkbox" type="checkbox" value="socialM" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  <label for="laravel-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Social Media Marketing</label>
              </div>
          </li>
          <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
              <div class="flex items-center pl-3">
                  <input v-model="services" id="laravel-checkbox" type="checkbox" value="emailM" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  <label for="laravel-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email Marketing</label>
              </div>
          </li>
          <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
              <div class="flex items-center pl-3">
                  <input v-model="services" id="laravel-checkbox" type="checkbox" value="ppc" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  <label for="laravel-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">PPC Advertising</label>
              </div>
          </li>
          <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
              <div class="flex items-center pl-3">
                  <input v-model="services" id="laravel-checkbox" type="checkbox" value="custsupport" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  <label for="laravel-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Customer Support</label>
              </div>
          </li>
          <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
              <div class="flex items-center pl-3">
                  <input v-model="services" id="laravel-checkbox" type="checkbox" value="googleB" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  <label for="laravel-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Google Bad Comments Removal</label>
              </div>
          </li>
          <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
              <div class="flex items-center pl-3">
                  <input v-model="services" id="laravel-checkbox" type="checkbox" value="BR" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                  <label for="laravel-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Business Ranking</label>
              </div>
          </li>
      </ul>
    </div>
  </div>
  <button @click.prevent="addEmployee" class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800">
  <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
      Add Lead
  </span>
</button>
</form>
</template>

<script>
/* eslint-disable */


// webdev : Web development
// trans : translations
// seo : Search engine Optimizations
// appdev : App Development
// socialM : Social Marketing
// emailM : Email MArketing
// ppc : ppc Advt
// custsupport : Customer Support
// googleB : Google bad comments removal
// Add positive comments google


// const createLead = Joi.object({
//   companyname: Joi.string().required(),
//   fullname:Joi.string().required(),
//   phonenumber:Joi.string(),
//   email:Joi.string(),
//   comments:Joi.string(),  =================
//   services:Joi.array().items(Joi.string().valid('a','b')).required(),  =========
//   leadMoney:Joi.number(),     =========
//   country:Joi.string().required(),
//   city:Joi.string().required(),
//   state:Joi.string().required(),
//   zipCode:Joi.string().required(),
//   skype:Joi.string(),
//   address:Joi.string().required(),
// });
import { useVuelidate } from '@vuelidate/core'
import { required , minLength ,email } from '@vuelidate/validators'
import { LOCATIONS } from '@/utils/helpers'
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  props:{
    userType:{
      default:'HUNTER'
    }
  },
  data() {
    return {
      companyname:"",
      fullname:"",
      phonenumber:"",
      email:"",
      country:LOCATIONS.find(l => l.country == 'Canada'),
      city:"",
      state:"",
      zipCode:"",
      skype:"",
      address:"",
      services:[],
      comments:"",
      locations:LOCATIONS
    }
  },
  validations () {
    return {
      companyname : { required },
      fullname: { required },
      phonenumber: {  required},
      email: { email,required },
      country: { required },
      city: { required },
      state: { required },
      zipCode: { required },
      skype: {  },
      address : {required},
      comments : { },
    }
  },
  computed:{ 
    callbackUrl:function() {
      if(this.role == 'BACKOFFICE') {
        return 'backoffice'
      }
      if(this.role == 'HUNTER') {
        return 'hunter'
      }
    }
  },
  methods: {
    async addEmployee() {
      console.log(this.services)
      console.log(this.country)
      if(!this.services.length) return this.$notify({ title:"Error",type: "error", text: "Please select atleast a one service" });
	    const isFormCorrect = await this.v$.$validate()
			if(!isFormCorrect) return
      try{
        let { data } = await this.axios.post('hunter/create-lead',{
          companyname: this.companyname,
          fullname:this.fullname,
          phonenumber: `(${this.country.calling_codes}) ${this.phonenumber}`,
          email:this.email,
          country:this.country.country,
          city:this.city,
          state:this.state,
          zipCode:this.zipCode,
          skype:this.skype,
          address:this.address,
          services:this.services,
          comments:this.comments,
        })
        this.$notify({ title:"Message",type: "success", text: "Lead added successfully" });
        this.$router.push(`/hunter/leads`)
      }catch(e) {
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
				this.$notify({ title: "Error", text: message });
      }
    }
  },
  mounted() {
    this.role = this.userType
    // alert(this.userType)
  }
  
}
</script>