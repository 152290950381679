<template>
  <AppHeader/>
  <div class="w-full mx-auto max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <div class="flex justify-end px-4 pt-4">
          <button id="dropdownButton" data-dropdown-toggle="dropdown" class="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5" type="button">
              <span class="sr-only">Open dropdown</span>
              <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                  <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"/>
              </svg>
          </button>
          <!-- Dropdown menu -->
          <div id="dropdown" class="z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
              <ul class="py-2" aria-labelledby="dropdownButton">
              <li>
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Edit</a>
              </li>
              <li>
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Export Data</a>
              </li>
              <li>
                  <a href="#" class="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete</a>
              </li>
              </ul>
          </div>
      </div>
      <div class="flex flex-col items-center pb-10">
          <img class="w-24 h-24 mb-3 rounded-full shadow-lg" :src="getSrc()" alt="Bonnie image"/>
          <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{{$store.state.core.userData.fullname}}</h5>
          <span class="text-sm text-gray-500 dark:text-gray-400">{{$store.state.core.userData.username}}</span>
          <div class="flex mt-4 space-x-3 md:mt-6">
              <a href="#" @click.prevent="$refs.fileInput.click()" class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Update Avatar</a>
          </div>
          <input @change="uploadAvatar" ref="fileInput" class="hidden" type="file" accept=".png,.jpg,.jpeg">
      </div>
  </div>

</template>
<script>
/* eslint-disable */
import AppHeader from '@/components/AppHeader.vue'
import helperMixin from '@/mixins/helpers'
export default {
  mixins:[helperMixin],
  components: {
    AppHeader
  },
  methods:{
    async uploadAvatar() {
      let file = this.$refs.fileInput.files[0]
      console.log(file)
      try{
        let filename = this.$store.state.core.userData.username + this.makeId(5)
        let mimetype = file.type
        let { data } = await this.axios.post('user/get-put-url',{
          filename:filename,
          mimetype:mimetype
        })
        let putUrl = data.message
        console.log(putUrl) 
          let result = await this.axios
            .request({
              method: "PUT",
              url: putUrl,
              data: file,
              // headers_list:['Content-Type:video/mp4'],
              headers: {
                "Content-Type": mimetype,
              },
              onUploadProgress: (p) => {
                console.log(p);
                console.log(Math.ceil((p.loaded / p.total) * 100));
                this.uploadProgress = Math.ceil((p.loaded / p.total) * 100);
                console.log(this.uploadProgress);
                // document.getElementById('progress').innerHTML = `${Math.ceil(p.progress * 100)}  %`
                //this.setState({
                //fileprogress: p.loaded / p.total
                //})
              },
            })

          console.log(result)
          await this.axios.put('user/update-avatar',{
            avatar:filename
          })
            // .then((data) => {
            //   console.log(data);
            //   resolve({
            //     filename: name,
            //     mimetype: mimeType,
            //   });
            // })
            // .catch((e) => {
            //   this.uploadingFiles = false;
            //   this.uploadProgress = 0;
            //   reject(e);
            // });
            let userData = this.$store.state.core.userData
            userData.avatar = filename
            this.$store.commit("userData",userData)
         this.$notify({ title:"Message",type: "success", text: "Avatar updated Successfully" });
        
      }catch(e) {
        console.log('tere was an errr')
       console.log(e)
				this.$notify({ title: "Error", text: 'there was an error' });
      }
    }
  }
}
</script>
