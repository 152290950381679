<template>
 <form class="w-full mx-auto px-20">
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
        Fullname
      </label>
      <input v-model="fullname" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500':v$.fullname.$errors.length}" id="grid-first-name" type="text" placeholder="Jane">
      <div class="input-errors mt-2" v-for="error of v$.fullname.$errors" :key="error.$uid">
          <p v-if="v$.fullname.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
      </div>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        Username
      </label>
      <input v-model="username" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500':v$.username.$errors.length}" id="grid-last-name" type="text" placeholder="Doe">
      <div class="input-errors mt-2" v-for="error of v$.username.$errors" :key="error.$uid">
          <p v-if="v$.username.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
      </div>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-2">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
        Password
      </label>
      <input v-model="password" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500':v$.password.$errors.length}" id="grid-city" type="text" placeholder="Albuquerque">
      <div class="input-errors mt-2" v-for="error of v$.password.$errors" :key="error.$uid">
          <p v-if="v$.password.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
      </div>
    </div>
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        User Role
      </label>
      <div class="relative">
        <select v-model="mutUserType" disabled class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
          <option value="HUNTER">HUNTER</option>
          <option value="BACKOFFICE">BACKOFFICE</option>
          <!-- <option>Texas</option> -->
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
  </div>
  <button @click.prevent="addEmployee" class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800">
  <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
      Add {{callbackUrl}} Employee
  </span>
</button>
</form>
</template>

<script>
/* eslint-disable */
import { useVuelidate } from '@vuelidate/core'
import { required , minLength } from '@vuelidate/validators'
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  props:{
    userType:{
      default:null
    }
  },
  data() {
    return {
      mutUserType: this.userType,
      username:"",
      password:"",
      fullname:"",
      role:""
    }
  },
  validations () {
    return {
      fullname: { required },
      username: { required },
      password: { required , minLength:minLength(5) },
      role: { required },
    }
  },
  computed:{ 
    callbackUrl:function() {
      if(this.role == 'BACKOFFICE') {
        return 'backoffice'
      }
      if(this.role == 'HUNTER') {
        return 'hunter'
      }
    }
  },
  methods: {
    async addEmployee() {
	    const isFormCorrect = await this.v$.$validate()
			if(!isFormCorrect) return
      try{
        let { data } = await this.axios.post('admin/create-user',{
          fullname:this.fullname,
          username:this.username,
          password:this.password,
          role:this.role
        })
        this.$notify({ title:"Message",type: "success", text: "User added successfully" });
        this.$router.push(`/admin/${this.callbackUrl}`)
      }catch(e) {
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
				this.$notify({ title: "Error", text: message });
      }
    }
  },
  mounted() {
    this.role = this.userType
    // alert(this.userType)
  }
  
}
</script>