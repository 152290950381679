<template>
 <AppBreadcrumb
    :links="[{text:'Dashboard',url:'/admin'},{text:'Head Hunters',url:'/admin/hunter'},{text:'Add Head Hunters',url:''}]"
  />
  <AddUser
  userType="HUNTER"
  />
</template>
<script>
import AddUser from '@/components/Admin/AddUser.vue'
import AppBreadcrumb from '@/components/AppBreadcrumb.vue'

export default {
  components:{
    AddUser,
    AppBreadcrumb
  }
}
</script>