<template>
  <button @click.prevent="()=> datePicker = !datePicker" v-if="data.backofficeId == $store.state.core.userData.id" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Schedule Lead</button>
 <button v-if="data.backofficeId == $store.state.core.userData.id" @click="declineLead" type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Decline Lead</button>
 <!-- v-if="getLeadLevel (data) == 6 || getLeadLevel(data) == 7" -->
      <router-link v-if="data.status == 'in_progress' || data.status=='contract_signed'" :to="`/office/send-invoice/${$route.params.id}`"  type="button" class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900">{{
        data.status == 'in_progress' ? 'Send Quote' : 'Send Invoice'
      }}</router-link>
  <div v-if="datePicker">
     <VueDatePicker 
      v-model="picked"
      position="left"
      placeholder="Schedule date/time"
      :min-date="new Date()"
      auto-apply="true"
     ></VueDatePicker>
     <textarea v-model="scheduleReason" id="message" rows="4" class=" mt-2 block p-2.5 sm:w-full md:w-1/3 text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your reasons here..."></textarea>
     <button @click.prevent="scheduleLead" class=" mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Schedule</button>
     <button @click.prevent="()=> datePicker = false" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Cancel</button>
  </div>
<div class=" p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <LeadDetails
      :data="data"
    />



  <div v-if="!data.status" class="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
    <span class="font-medium">This Lead is open to accept</span>
  </div>
  <div  v-if="data.status == 'in_progress'" class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
    <span class="font-medium">This Lead is in progress</span>
  </div>
  <div  v-if="data.status == 'payment_received'" class="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-300" role="alert">
    <span class="font-medium">This Lead is complete</span>
  </div>
  <div  v-if="data.status == 'declined'" class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-300" role="alert">
    <span class="font-medium">This Lead is declined</span>
  </div>
  <div  v-if="data.status == 'scheduled'" class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
    <span class="font-medium">This Lead is scheduled</span>
  </div>

    <a v-if="!data.status" @click.prevent="grabLead" href="#" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        Grab This Lead !!!
        <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
        </svg>
    </a>
    <a v-if="data.status == 'scheduled'" @click.prevent="unscheduleLead" href="#" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        Unschedule!!!
        <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
        </svg>
    </a>
  <div v-if="data.id">
    <LeadComments
      v-if="data.backofficeId == $store.state.core.userData.id"
      :lead="data"
    /> 
  </div>
</div>

</template>
<script>
/* eslint-disable */
// import helperMixin
import LeadDetails from '@/components/Small/LeadDetails.vue'
import LeadComments from '@/components/LeadComments.vue'
import SendInvoice from '@/components/SendInvoice.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import helperMixin from '@/mixins/helpers'
// const picked = ref(new Date())
export default {
  mixins:[helperMixin],
  components: {
    LeadComments,
    LeadDetails,
    VueDatePicker,
    SendInvoice
  },
  data() {
    return {
      data:{},
      picked:new Date(),
      datePicker:false,
      scheduleReason:'',
    }
  },
  async mounted() {
    try{
      let { data } = await this.axios.get(`backoffice/lead/${this.$route.params.id}`)
      this.data = data.data
      // this.$notify({ title:"Message",type: "success", text: "User added successfully" });
    }catch(e) {
      console.log('tere was an errr')
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: message });
    }
  },
  methods: {
    //grab-lead
    async grabLead() {
      try{
        let { data } = await this.axios.put(`backoffice/grab-lead/${this.$route.params.id}`)
        this.$swal.fire(
          'Good job!',
          'Lead Grabbed Successfully!',
          'success'
        )
        this.$router.push('/office/myleads')

        // this.$notify({ title:"Message",type: "success", text: "Lead Grabbed Successfully" });
      }catch(e) {
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
    },
    async unscheduleLead() {
      try{
        let { data } = await this.axios.put(`backoffice/unschedule-lead/${this.$route.params.id}`)
        this.$swal.fire(
          'Good job!',
          'Lead Grabbed Successfully!',
          'success'
        )
        this.$router.push('/office/myleads')

        // this.$notify({ title:"Message",type: "success", text: "Lead Grabbed Successfully" });
      }catch(e) {
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
    },
    async declineLead() { 
      let result = await this.$swal({
          title: "Decline this lead?",
          text: "Are you sure? You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes, Remove it!"
      })
      if(!result.isConfirmed) { this.datePicker = false; return } 
      try{
        let { data } = await this.axios.put(`backoffice/decline-lead/${this.$route.params.id}`)
        this.$swal.fire(
          'Great!',
          'Lead Declined Successfully!',
          'success'
        )
        this.$router.push('/office/myleads')

        // this.$notify({ title:"Message",type: "success", text: "Lead Grabbed Successfully" });
      }catch(e) {
        console.log(e)
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
    },
    async scheduleLead() { 
      console.log(this.picked)
        let result = await this.$swal({
          title: "Reschedule this lead?",
          text: "Are you sure? You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes, Re-schedule it!"
      })
      if(!result.isConfirmed) { this.datePicker = false; return } 
      try{
        let { data } = await this.axios.put(`backoffice/schedule-lead/${this.$route.params.id}`,{
          date:this.picked,
          scheduleReason:this.scheduleReason
        })
        this.$swal.fire(
          'Great!',
          'Lead Schedueld Successfully!',
          'success'
        )
        this.$router.push('/office/myleads')

        // this.$notify({ title:"Message",type: "success", text: "Lead Grabbed Successfully" });
      }catch(e) {
        console.log(e)
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
      console.log(result)
    }
  },
  
}
</script>
