<template>
   <span v-if="!item.status" class="relative flex h-3 w-3">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
        <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
    </span>
    <span v-if="item.status == 'in_progress'" class="relative flex h-3 w-3">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-300  opacity-75"></span>
        <span class="flex w-3 h-3 bg-yellow-300 rounded-full"></span>
    </span>
    <span v-if="item.status == 'payment_received'" class="relative flex h-3 w-3">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-300  opacity-75"></span>
      <span  class="flex w-3 h-3 bg-green-300 rounded-full"></span>
    </span>
    <span v-if="item.status == 'declined'" class="relative flex h-3 w-3">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500  opacity-75"></span>
      <span  class="flex w-3 h-3 bg-red-500 rounded-full"></span>
  </span>
    <span v-if="item.status == 'scheduled'" class="relative flex h-3 w-3">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-500  opacity-75"></span>
      <span  class="flex w-3 h-3 bg-purple-500 rounded-full"></span>
  </span>
  <span v-if="item.status == 'contract_send'" class="relative flex h-3 w-3">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink-300  opacity-75"></span>
      <span  class="flex w-3 h-3 bg-pink-300 rounded-full"></span>
  </span>
   <span v-if="item.status == 'contract_signed'" class="relative flex h-3 w-3">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-900  opacity-75"></span>
      <span  class="flex w-3 h-3 bg-green-900 rounded-full"></span>
  </span>
  <span v-if="item.status == 'invoice_paid'" class="relative flex h-3 w-3">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink-600  opacity-75"></span>
      <span  class="flex w-3 h-3 bg-pink-600 rounded-full"></span>
  </span>
  <span v-if="item.status == 'invoice_send'" class="relative flex h-3 w-3">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-600  opacity-75"></span>
      <span  class="flex w-3 h-3 bg-indigo-600 rounded-full"></span>
  </span>
   <span v-if="item.status == 'quote_send'" class="relative flex h-3 w-3">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-200  opacity-75"></span>
      <span  class="flex w-3 h-3 bg-gray-200 rounded-full"></span>
  </span>
</template>
<script>
export default  {
  props:['item']
}
</script>