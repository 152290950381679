<template>
<AppLoader
/>
  <div>Accpect quoye</div>
</template>
<script>
/* eslint-disable */
import AppLoader from '@/components/AppLoader.vue' 
export default  {
  components:{
    AppLoader
  },
  async mounted() {
    try{
        let { data } = await this.axios.get(`public/accept-qoute/${this.$route.params.id}`)
        if(data.message == 'lead_accpected') {
          alert('lead_accpected')
          window.location.href = "https://dialogmakers-international.com/quote-accpected"
        }
        
      }catch(e) {
        let { data : { message } } = e.response
        if(message == 'lead_not_found') {
          alert('lead_not_found')
          window.location.href = "https://dialogmakers-international.com/404"
        }
        if(message == 'lead_already_accpected') {
          // alert('lead_already_accpected')
          window.location.href = "https://dialogmakers-international.com/quote-accpected"
        }
        
      }
  }
}
</script>
