const helperMixin = {
  data(){ 
    return {
      leadLevels: {
        in_progress:1,
        scheduled:2,
        declined:3,
        payment_received:4,
        contract_send:5,
        contract_signed:6,
        quote_send:7,
        invoice_send: 8,
        invoice_paid:9
      }
    }
  },
  methods: {
    logOutUser() {
      this.$store.commit("userData",{})
      this.$store.commit("backofficeLoggedIn",false)
      this.$store.commit("hunterLoggedIn",false)
      this.$store.commit("adminloggedIn",false)
      this.$store.commit("token",null)
    },
    getSrc(user = null) {
      if(user) {
        if(user.avatar) {
          return `https://storage.googleapis.com/dialogmakers/${user.avatar}`
        }else {
          return require('@/assets/images/dummy-profile.png')
        }
      }else {
        if(this.$store.state.core.userData.avatar) {
          return `https://storage.googleapis.com/dialogmakers/${this.$store.state.core.userData.avatar}`
        }else {
          return require('@/assets/images/dummy-profile.png')
        }
      }
    },
    getLeadLevel(lead) {
      return this.leadLevels[lead.status]
    },
    makeId(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    }
  }
}

export default helperMixin