<template>
   <app-loader></app-loader>
   <router-view/>
   <notifications style="margin-right:20px;margin-top:50px" />
</template>
<script>
/* eslint-disable */
import AppHeader from '@/components/AppHeader.vue'
import AdminSidebar from '@/components/Admin/AdminSidebar.vue'
import AppLoader from './components/AppLoader.vue' 

// import AppTable from '@/components/AppTable.vue'
// import AppForm from '@/components/AppForm.vue'
export default {
  components: {
    AppHeader,
    AdminSidebar,
    AppLoader
    // AppTable,
    // AppForm
  },
  methods: {
    
  }
}
//transform-none
</script>
