<template>
<ul class="mb-2 items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
    <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
        <div class="flex items-center pl-3">
            <span class="flex w-3 h-3 bg-yellow-300 rounded-full mr-2"></span>
            <input @change="applyFilter()"  v-model="filter.in_progress" id="in_progress" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="vue-checkbox-list" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">In Progress</label>
        </div>
    </li>
    <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
        <div class="flex items-center pl-3">
           <span  class="flex w-3 h-3 bg-purple-500 rounded-full mr-2"></span>
            <input @change="applyFilter()" v-model="filter.scheduled" id="scheduled" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="react-checkbox-list" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Scheduled</label>
        </div>
    </li>
    <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
        <div class="flex items-center pl-3">
          <span  class="flex w-3 h-3 bg-red-500 rounded-full mr-2"></span>
            <input @change="applyFilter()" v-model="filter.declined" id="declined" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="angular-checkbox-list" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Declined</label>
        </div>
    </li>
    <!-- <li class="w-full dark:border-gray-600">
        <div class="flex items-center pl-3">
          <span  class="flex w-3 h-3 bg-green-500 rounded-full mr-2"></span>
            <input @change="applyFilter()" v-model="filter.payment_received" id="payment_received" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="laravel-checkbox-list" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Paid</label>
        </div>
    </li> -->
    <li class="w-full dark:border-gray-600">
        <div class="flex items-center pl-3">
          <span  class="flex w-3 h-3 bg-pink-300 rounded-full mr-2"></span>
            <input @change="applyFilter()" v-model="filter.contract_send" id="contract_send" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="laravel-checkbox-list" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Contract Send</label>
        </div>
    </li>
    <li class="w-full dark:border-gray-600">
        <div class="flex items-center pl-3">
          <span  class="flex w-3 h-3 bg-green-900 rounded-full mr-2"></span>
            <input @change="applyFilter()" v-model="filter.contract_signed" id="contract_signed" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="laravel-checkbox-list" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Contract Signed</label>
        </div>
    </li>
    <li class="w-full dark:border-gray-600">
        <div class="flex items-center pl-3">
          <span  class="flex w-3 h-3 bg-pink-600 rounded-full mr-2"></span>
            <input @change="applyFilter()" v-model="filter.invoice_paid" id="invoice_paid" type="checkbox" value="" class="w-4 h-4 text-600-600 bg-gray-100 border-gray-300 rounded focus:ring-600-500 dark:focus:ring-600-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="laravel-checkbox-list" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Invoice Paid</label>
        </div>
    </li>
    <li class="w-full dark:border-gray-600">
        <div class="flex items-center pl-3">
          <span  class="flex w-3 h-3 bg-gray-200 rounded-full mr-2"></span>
            <input @change="applyFilter()" v-model="filter.quote_send" id="quote_send" type="checkbox" value="" class="w-4 h-4 text-600-600 bg-gray-100 border-gray-300 rounded focus:ring-600-500 dark:focus:ring-600-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="laravel-checkbox-list" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Quote Send</label>
        </div>
    </li>
     <li class="w-full dark:border-gray-600">
        <div class="flex items-center pl-3">
          <span  class="flex w-3 h-3 bg-indigo-600 rounded-full mr-2"></span>
            <input @change="applyFilter()" v-model="filter.invoice_send" id="invoice_send" type="checkbox" value="" class="w-4 h-4 text-600-600 bg-gray-100 border-gray-300 rounded focus:ring-600-500 dark:focus:ring-600-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="laravel-checkbox-list" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Invoice Send</label>
        </div>
    </li>
    <!-- <li class="w-full dark:border-gray-600">
        <div class="flex items-center pl-3">
          <span  class="flex w-3 h-3 bg-indigo-600 rounded-full mr-2"></span>
            <input @change="applyFilter()" v-model="filter.invoice_send" id="invoice_send" type="checkbox" value="" class="w-4 h-4 text-600-600 bg-gray-100 border-gray-300 rounded focus:ring-600-500 dark:focus:ring-600-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="laravel-checkbox-list" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Invoice Send</label>
        </div>
    </li> -->
    <li class="w-full dark:border-gray-600">
        <div class="flex items-center pl-3">
          <span  class="flex w-3 h-3 bg-red-100 rounded-full mr-2"></span>
            <input @change="applyFilter()" v-model="filter.quote_accepted" id="quote_accepted" type="checkbox" value="" class="w-4 h-4 text-600-600 bg-green-100 border-gray-300 rounded focus:ring-600-500 dark:focus:ring-600-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="laravel-checkbox-list" class="w-full py-3 ml-2 text-sm font-medium text-green-900 dark:text-gray-300">Quote Accepted</label>
        </div>
    </li>

    
</ul>
</template>
<script>
export default {
  data() {
    return {
      filter : this.$store.state.core.leadsFilter
    }
  },
  methods: {
    getLeadQuery() {
       let queryStr = [];
      Object.keys(this.filter).forEach((leadState)=>{
        if(this.filter[leadState]) {
          queryStr.push(leadState)
        }
      })
      return queryStr.join(",")
    },
    applyFilter() {
      let toApplyFilter = Object.values(this.filter).filter(f => f == true)
      if(toApplyFilter.length == 0) {
        this.filter[event.target.id] = true
        return
      }
      console.log(toApplyFilter)
      this.$emit('filterChanged',this.getLeadQuery())
       this.$store.commit("leadsFilter",this.filter)
    }
  }
}
</script>
