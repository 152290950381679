<template>
  
<img @click.prevent="() => showDropdown = !showDropdown"  class="w-10 h-10 rounded-full cursor-pointer" :src="getSrc()" alt="User dropdown">

<!-- Dropdown menu -->
<div class="drop relative">
<div id="userDropdown" class="z-10 absolute  md:top-10 md:right-2  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600" :class="{'hidden':!showDropdown}">
    <div class="px-4 py-3 text-sm text-gray-900 dark:text-white">
      <div>{{$store.state.core.userData.fullname}}</div>
      <div class="font-medium truncate">{{$store.state.core.userData.username}}</div>
    </div>
    <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="avatarButton">
      <li>
        <router-link to="/profile" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Profile</router-link>
      </li>
    </ul>
    
</div>
</div>
</template>

<script>
/* eslint-disable */

import helperMixin from '@/mixins/helpers'

export default  {
  mixins:[helperMixin],
  data() {
    return {
      showDropdown: false
    }
  },
  methods: {
   
  }
}
</script>
