<template>
  <LeadDetails
  v-if="data.id"
  :data="data"
  />
  <LeadComments
  v-if="data.id"
  :lead="data"
  />
</template>

<script>
import LeadDetails from '@/components/Small/LeadDetails.vue'
import LeadComments from '@/components/LeadComments.vue'

export default {
  components:{
    LeadDetails,
    LeadComments
  },
  data() {
    return {
      data:{},
    }
  },
  async mounted() {
    try{
      let { data } = await this.axios.get(`hunter/lead/${this.$route.params.id}`)
      this.data = data.data
      // this.$notify({ title:"Message",type: "success", text: "User added successfully" });
    }catch(e) {
      console.log('tere was an errr')
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: message });
    }
  },
}
</script>
