<template>
  <div class="grid lg:grid-cols-3 sm:grid-cols-1 gap-4 mb-4">
         <div v-for="product in products" :key="product.product_name" class="flex items-center justify-center  rounded dark:bg-gray-800">
           <DialogProduct
            :product="product"
            @selectProduct="selectProduct"
           />
         </div>
      </div>
    <Modal 
      persistent
      size="sdf" 
      v-if="isShowModal" 
      @close="closeModal">
      <template #header>
        <div class="flex items-center text-lg">
          Contract for {{activeProduct.product_name}}
        </div>
      </template>
      <template #body>
        <section class="bg-white dark:bg-gray-900">
          <div class="px-4 mx-auto max-w-2xl">
              <img :src="`https://storage.googleapis.com${activeProduct.image}`">
              <form action="#">
                 <div class="mb-4">
                  <label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Sending contract to</label>
                  <select v-model="form.lead_id" @change="autoPopulateForm()" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                    <option :value="null">Select lead</option>
                    <option v-for="lead in formLeads" :key="lead.id" :value="lead.id">
                      {{lead.fullname}} &nbsp; &nbsp;&nbsp; status ({{lead.status}})
                    </option>
                  </select>
                    <div class="input-errors mt-2" v-for="error of v$.form.lead_id.$errors" :key="error.$uid">
                          <p v-if="v$.form.lead_id.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
                      </div>
                  </div>
                  <div class="grid gap-4 sm:grid-cols-2 sm:gap-6">
                      <div class="w-full">
                          <label for="brand" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Client's Firstname</label>
                          <input type="text" v-model="form.Client_FirstName" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Client's Firstname" required="">
                           <div class="input-errors mt-2" v-for="error of v$.form.Client_FirstName.$errors" :key="error.$uid">
                              <p v-if="v$.form.Client_FirstName.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
                          </div>
                      </div>
                      <div class="w-full">
                          <label for="price" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Client's Lastname</label>
                          <input v-model="form.Client_LastName" type="text" name="price" id="price" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Client's Lastname" required="">
                          <div class="input-errors mt-2" v-for="error of v$.form.Client_LastName.$errors" :key="error.$uid">
                              <p v-if="v$.form.Client_LastName.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
                          </div>
                      </div>
                      <div class="w-full">
                          <label for="brand" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Client Title</label>
                          <input v-model="form.Client_Title" type="text" name="brand" id="brand" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Client Title" required="">
                          <div class="input-errors mt-2" v-for="error of v$.form.Client_Title.$errors" :key="error.$uid">
                              <p v-if="v$.form.Client_Title.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
                          </div>
                      </div>
                      <div class="w-full">
                          <label for="price" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Client Company</label>
                          <input v-model="form.Client_Company" type="text" name="price" id="price" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Client Company" required="">
                           <div class="input-errors mt-2" v-for="error of v$.form.Client_Company.$errors" :key="error.$uid">
                              <p v-if="v$.form.Client_Company.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
                          </div>
                      </div>
                      <div class="w-full">
                          <label for="brand" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Client Phone</label>
                          <input v-model="form.Client_Phone" type="text" name="brand" id="brand" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Client Phone" required="">
                           <div class="input-errors mt-2" v-for="error of v$.form.Client_Phone.$errors" :key="error.$uid">
                              <p v-if="v$.form.Client_Phone.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
                          </div>
                      </div>
                      <div class="w-full">
                          <label for="price" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Client Email</label>
                          <input v-model="form.Client_Email" type="text" name="price" id="price" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Client Email" required="">
                           <div class="input-errors mt-2" v-for="error of v$.form.Client_Email.$errors" :key="error.$uid">
                              <p v-if="v$.form.Client_Email.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
                          </div>
                      </div>
                      <div>
                          <label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Contract Type</label>
                          <select v-model="form.Contract_Type" id="category" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                              <option value="ongoing">Ongoing</option>
                              <option value="fixed">Fixed</option>
                          </select>
                      </div>
                      <div>
                          <label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Contract Duration</label>
                          <select v-model="form.Contract_Valid_For" id="category" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                              <option value="12">12 Months</option>
                              <option value="6">6 Months</option>
                          </select>
                      </div>
                       <VueDatePicker 
                        v-model="form.Contract_Start"
                        position="left"
                        placeholder="Contract start date"
                        :min-date="new Date()"
                        auto-apply="true"
                      ></VueDatePicker>
                      <div class="sm:col-span-2">
                          <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Client Address</label>
                          <textarea v-model="form.Client_Address" id="description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Client address here"></textarea>
                          <div class="input-errors mt-2" v-for="error of v$.form.Client_Address.$errors" :key="error.$uid">
                              <p v-if="v$.form.Client_Address.$error" class="text-red-500 text-xs italic">{{ error.$message }}</p>
                          </div>
                      </div>
                      <div class="sm:col-span-2">
                          <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Additional Comments</label>
                          <textarea v-model="form.Additional_Comments" id="description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Additional Comments"></textarea>
                      </div>
                  </div>
                  <div class="p-4">
                    <a style="color:blue" target="_blank" :href="previewLink">{{previewLink}}</a>
                  </div>
              </form>
              </div>
         </section>
      </template>
      <template #footer>
        <div class="flex">
          <button @click="closeModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
            Cancel
          </button>
           <button @click.prevent="()=>sendContract(true)" type="button" class="ml-2 text-white bg-yellow-400 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-400 dark:focus:ring-yellow-400">
            Preview Generated Contract (Recommended)
          </button>
          <button @click.prevent="()=>sendContract(false)" type="button" class=" ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Send
          </button>
        </div>
      </template>
    </Modal>
</template>

<script>
/* eslint-disable */
import { Modal } from 'flowbite-vue'
import DialogProduct from '@/components/Small/DialogProduct.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { useVuelidate } from '@vuelidate/core'
import { required , minLength ,email } from '@vuelidate/validators'
import ENV from '@/env';
export default  {
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    DialogProduct,
    Modal,
    VueDatePicker
  },
  data() {
    return {
      products:[],
      isShowModal:false,
      activeProduct:null,
      previewLink:null,
      form: {
        lead_id:null,
        service_id: null,
        Client_FirstName:"",
        Client_LastName:"",
        Client_Title:"",
        Client_Company:"",
        Client_Phone:"",
        Client_Email:"",
        Client_Address:"",
        Contract_Type:"fixed",
        Contract_Valid_For:"6",
        Contract_Start:new Date(),
        Additional_Comments:""
      },
      leads:[],
      formLeads:[]
    }
  },
  validations () {
    return {
      form: {
        lead_id: { required },
        service_id: { required },
        Client_FirstName: { required },
        Client_LastName: { required  },
        Client_Title: { required },
        Client_Company: { required },
        Client_Phone: { required },
        Client_Email: { email,required },
        Client_Address: { required ,minLength:minLength(5) },
        Contract_Type: { required },
        Contract_Valid_For: { required },
        Contract_Start: { required },
      }
    }
  },
  async mounted() {
    try{
      let { data } = await this.axios.get('public/products')
      this.products = data
      await this.getLeads()
      if(this.leads.length) {
        // this.form.lead_id = this.leads[0].id
      }
    }catch(e) {
      console.log('tere was an errr')
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: message });
    }
  },
  methods: {
    selectProduct(product) {
      if(!this.leads.length) {
        this.$notify({ title:"Error",type: "error", text: "No leads with send Quotations found" });
         this.closeModal();
         return
      }
      console.log(product)
      let contractFound = this.leads.filter((lead)=>{
        let leadContracts = lead.toSendContracts.split(",")?.map(id => Number(id))
        console.log(leadContracts)
        if(!leadContracts.length) return false
        if(leadContracts.includes(product.id)){
          return true
        }
      })
      if(!contractFound.length){
        return this.$notify({ title:"Error",type: "error", text: "No leads with send Quotations for this product found" });
      }
      this.formLeads = contractFound 
      console.log(contractFound,"contractFound")
      this.activeProduct = product
      
      this.form.service_id = product.id
      

      this.isShowModal = true
    },
    closeModal() {
      this.activeProduct = null,
      this.formLeads = []
      this.isShowModal = false
      this.resetForm()
    },
    async sendContract(preview) {
      const isFormCorrect = await this.v$.$validate()
      if(!isFormCorrect) return 
      try{
        let { data } = await this.axios.post(`backoffice/generate-contract${ preview ? '?preview=yes' : ''}`,this.form)
        if(preview) {
          this.previewLink = ENV.baseURL()+data.file
          return
        }
        // this.leads = this.leads.filter(l => l.id != this.form.lead_id )
        await this.getLeads()
        this.closeModal()
        this.$notify({ title:"Success",type: "success", text: "Contract Send Successfully" });
      }catch(e) {
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
    },
    async getLeads() { 
      try{
        let { data } = await this.axios.get(`backoffice/get-my-leads?leads=quote_accepted`)
        this.leads = data.data
        // this.$notify({ title:"Message",type: "success", text: "User added successfully" });
      }catch(e) {
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
    }, 
    autoPopulateForm() {
      let selectLead = this.leads.find(l => l.id == this.form.lead_id)
      if(!selectLead) return this.resetForm()
      this.form.service_id = this.activeProduct.id
      this.form.Client_FirstName = selectLead.fullname.split(" ")[0]
      this.form.Client_LastName = selectLead.fullname.split(" ")[1]
      this.form.Client_Company = selectLead.companyname
      this.form.Client_Email = selectLead.email
      this.form.Client_Address = selectLead.address
      this.form.Client_Phone = selectLead.phonenumber
    },
    resetForm() {
      this.form.service_id = null
      this.form.Client_FirstName = "",
      this.form.Client_LastName="",
      this.form.Client_Title="",
      this.form.Client_Company="",
      this.form.Client_Phone="",
      this.form.Client_Email="",
      this.form.Client_Address="",
      this.form.Contract_Type="fixed",
      this.form.Contract_Valid_For="6",
      this.form.Additional_Comments = '',
      this.previewLink = null
      this.form.Contract_Start=new Date()
    }
  }
}
</script>
