<template>
  <div>
     <AppBreadcrumb
      :links="[{text:'Dashboard',url:'/admin'},{text:'All Leads',url:''}]"
    />
     <EasyDataTable
        buttons-pagination
          border-cell
          :alternating="true"
          theme-color="f"
          rows-per-page="20"
          :headers="headers"
          :items="items"
          search-field="email"
          :search-value="searchValue"
        >
        <template #item-createdAt="item">
          <div class="operation-wrapper">
          <span  class="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
              <svg aria-hidden="true" class="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
              {{ getTime(item) }}
            </span>
          </div>
        </template>
         <template #item-operation="item">
          <div class="operation-wrapper">

           <router-link :to="`edit-lead/${item.id}`" ><Icon icon="zondicons:align-justified" height="24" :inline="true" /></router-link>
          </div>
        </template>
        <template #item-status="item">
          <div class="operation-wrapper">
            <span v-if="!item.status" class="relative flex h-3 w-3">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
            </span>
            <span v-if="item.status == 'in_progress'" class="relative flex h-3 w-3">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-300  opacity-75"></span>
                <span class="flex w-3 h-3 bg-yellow-300 rounded-full"></span>
            </span>
            <span v-if="item.status == 'payment_received'" class="relative flex h-3 w-3">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500  opacity-75"></span>
              <span  class="flex w-3 h-3 bg-green-500 rounded-full"></span>
            </span>
            <span v-if="item.status == 'declined'" class="relative flex h-3 w-3">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500  opacity-75"></span>
              <span  class="flex w-3 h-3 bg-red-500 rounded-full"></span>
          </span>
           <span v-if="item.status == 'scheduled'" class="relative flex h-3 w-3">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-500  opacity-75"></span>
              <span  class="flex w-3 h-3 bg-purple-500 rounded-full"></span>
          </span>
          </div>
        </template>
        </EasyDataTable>
  </div>
</template>
<script>
/* eslint-disable */
//<Icon icon="ic:outline-warning" height="24" :inline="true" />
//https://icon-sets.iconify.design/zondicons/align-justified/
import { Icon } from '@iconify/vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue'
import { getTime } from '@/utils/helpers'
export default {
  components:{
    Icon,
    AppBreadcrumb
  },
  data(){
    return {
      loading:false,
      searchValue:"",
      headers:[
        { text: "Country", value: "country"  },
        { text: "Client Fullname", value: "fullname"  },
        { text: "Services", value: "services"  },

        { text: "Hunter", value: "hunter.username"  },
        { text: "Backoffice", value: "backoffice.username"  },

        { text: "Created At", value: "createdAt"  },
        { text: "Status", value: "status"  },
        { text: "Operation", value: "operation" }
      ],
       items : []
    }
  },
  async mounted(){ 
    try{
      let { data } = await this.axios.get('admin/get-leads?leads=payment_received')
      this.items = data.data
      // this.$notify({ title:"Message",type: "success", text: "User added successfully" });
    }catch(e) {
      console.log('tere was an errr')
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: message });
    }
  },
  methods:{ 
    getTime(item) {
      return getTime(item)
    }
  }
}
</script>