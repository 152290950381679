<template>
  <button v-if="data.status != 'payment_received'"  @click="declineLead" type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Decline Lead</button>
   <button  @click="releasePayment" type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900">Release Payment</button>
   <button v-if="!data.stripeCustomerId"  @click.prevent="createStripeCustomer" type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Register in Stripe</button>
    <button   @click.prevent="deleteLead" type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Delete Lead</button>
<div class=" p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    
  <LeadDetails
  :data="data"
  />

   <LeadComments
      v-if="data.id"
      :lead="data"
    /> 
</div>

</template>
<script>
/* eslint-disable */
import LeadComments from '@/components/LeadComments.vue'
import LeadDetails from '@/components/Small/LeadDetails.vue'
export default {
  components:{
    LeadComments,
    LeadDetails
  },
  data() {
    return {
      data:{}
    }
  },
  async mounted() {
    try{
      let { data } = await this.axios.get(`admin/get-lead/${this.$route.params.id}`)
      this.data = data.data
      console.log(this.data)
      // this.$notify({ title:"Message",type: "success", text: "User added successfully" });
    }catch(e) {
      console.log('tere was an errr')
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: message });
    }
  },
  methods: {
    async releasePayment() {
      var getAmount = await this.$swal({
        title: 'Enter the payment amount',
        input: 'text',
        inputPlaceholder: 'Amount in USD',
        showCloseButton: true,
        inputValidator: (value) => {
          if(isNaN(value)) {
            return 'Please enter a integer'
          }
        }     
      });
      let amount = getAmount.value
      if(isNaN(amount)) return
      try{
        let { data } = await this.axios.post(`admin/release-payment`,{
          leadId: this.$route.params.id,
          amount: amount
        })
        this.$swal.fire(
          'Great!',
          'Payment for lead updated Successfully!',
          'success'
        )
        this.$router.push('/admin/leads')

        // this.$notify({ title:"Message",type: "success", text: "Lead Grabbed Successfully" });
      }catch(e) {
        console.log(e)
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
    },
    //grab-lead
     async declineLead() { 
      let result = await this.$swal({
          title: "Decline this lead?",
          text: "Are you sure? You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes, Remove it!"
      })
      if(!result.isConfirmed) { this.datePicker = false; return } 
      try{
        let { data } = await this.axios.put(`admin/decline-lead/${this.$route.params.id}`)
        this.$swal.fire(
          'Great!',
          'Lead Declined Successfully!',
          'success'
        )
        this.$router.push('/admin/leads')

        // this.$notify({ title:"Message",type: "success", text: "Lead Grabbed Successfully" });
      }catch(e) {
        console.log(e)
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
    },
    async deleteLead() {
      let result = await this.$swal({
          title: "Decline this lead?",
          text: "Are you sure? You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes, Remove it!"
      })
      if(!result.isConfirmed) { this.datePicker = false; return } 
      try{
        let { data } = await this.axios.delete(`admin/lead/${this.$route.params.id}`)
        this.$swal.fire(
          'Great!',
          'Lead Deleted Successfully!',
          'success'
        )
        this.$router.push('/admin/leads')

        // this.$notify({ title:"Message",type: "success", text: "Lead Grabbed Successfully" });
      }catch(e) {
        console.log(e)
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
    },
    async createStripeCustomer() {
      try{
        let { data } = await this.axios.post(`admin/create-stripe-customer`,{
          leadId:this.$route.params.id
        })
        this.$swal.fire(
          'Great!',
          'Registered Successfully!',
          'success'
        )
        
      }catch(e) {
        console.log(e)
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
    }
  },
  
}
</script>
