<template>
  <div class=" rounded overflow-hidden shadow-lg">
    <img class="w-full"  :src="`https://storage.googleapis.com${product.image}`" alt="Sunset in the mountains">
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">{{ product.product_name}}</div>
      <p class="text-gray-700 text-base">
        {{ product.Description}}
      </p>
    </div>
    <div class="px-6 pt-4 pb-2">
      <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
        Price: {{product.price}} USD {{product.onetime ? '' : 'per '+product.per}}
      </span>
      <span v-if="product.discount_percent" class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Discount% {{product.discount_percent}}</span>
      <span v-if="product.discount_price" class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Discount Price: {{product.discount_price}}</span>
      <button v-if="$store.state.core.userData.role == 'BACKOFFICE' && product.template" @click="selectProduct" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Send Contract</button>
    </div>
  </div>
</template>
<script>
export default {
  props:['product'],
  methods: {
    selectProduct() {
      this.$emit('selectProduct',this.product)
    }
  },
}
</script>
