<template>
   <OfficeSidebar/>
<div class="p-4 sm:ml-64">
   <AppHeader/>
  <div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
      <router-view></router-view>
   </div>
</div>
</template>
<script>
/* eslint-disable */
import AppHeader from '@/components/AppHeader.vue'
import OfficeSidebar from '@/components/Backoffice/OfficeSidebar.vue'
// import AppTable from '@/components/AppTable.vue'
// import AppForm from '@/components/AppForm.vue'
export default {
  components: {
    AppHeader,
    OfficeSidebar,
    // AppTable,
    // AppForm
  },
  methods: {
    
  }
}
//transform-none
</script>
