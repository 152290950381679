<template>
<div class="loader-wrapper" v-if="$store.state.core.loading">
  <div class="loader">Loading . . .</div>
</div>
</template>



<script>
export default {
	
}
</script>
<style>
  .loader-wrapper{
		height: 100%;
    width: 100%;
    position: fixed;
    z-index: 999;
    opacity: 0.7;
     top: 0;
		left: 0; 
    background: black;
    transition: 0.5s;

	}
  .loader {
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: helvetica, arial, sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    color: white;
    letter-spacing: 0.2em;
    z-index:99999
  }
 
  
</style>