<template>
  <div>
     <AppBreadcrumb
      :links="[{text:'Dashboard',url:'/admin'},{text:'All Leads',url:''}]"
    />
     <LeadsSelect
      ref="leadFliter"
      @filterChanged = filterChanged
    />
     <EasyDataTable
        buttons-pagination
          border-cell
          :alternating="true"
          theme-color="f"
          rows-per-page="20"
          :headers="headers"
          :items="items"
          search-field="email"
          :search-value="searchValue"
        >
        <template #item-createdAt="item">
          <div class="operation-wrapper">
          <span  class="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
              <svg aria-hidden="true" class="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
              {{ getTime(item) }}
            </span>
          </div>
        </template>
         <template #item-operation="item">
          <div class="operation-wrapper">

           <router-link :to="`/office/leadinfo/${item.id}`"><Icon icon="zondicons:align-justified" height="24" :inline="true" /></router-link>
          </div>
        </template>
        <template #item-status="item">
          <div class="operation-wrapper">
            <LeadStatus
            :item="item"
            />
          </div>
        </template>
        </EasyDataTable>
  </div>
 
</template>
<script>
/* eslint-disable */
//<Icon icon="ic:outline-warning" height="24" :inline="true" />
//https://icon-sets.iconify.design/zondicons/align-justified/
import { Icon } from '@iconify/vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue'
import { getTime } from '@/utils/helpers'
import LeadsSelect from '@/components/Small/LeadsSelect.vue'
import LeadStatus from '@/components/Small/LeadStatus.vue'
export default {
  components:{
    Icon,
    AppBreadcrumb,
    LeadsSelect,
    LeadStatus
  },
  data(){
    return {
      loading:false,
      searchValue:"",
      headers:[
        { text: "Country", value: "country"  },
        { text: "Client Fullname", value: "fullname"  },
        { text: "Services", value: "services"  },
        { text: "Status", value: "status"  },
        { text: "Hunter", value: "hunter.fullname"  },
        { text: "Created At", value: "createdAt"  },

        { text: "Operation", value: "operation" }
      ],
       items : []
    }
  },
  async mounted(){ 
    let filter = this.$refs.leadFliter.getLeadQuery()
    this.filter = filter
    this.getData()
  },
  methods:{
    async getData() { 
      try{
        let { data } = await this.axios.get(`backoffice/get-my-leads?leads=${this.filter}`)
        this.items = data.data
        // this.$notify({ title:"Message",type: "success", text: "User added successfully" });
      }catch(e) {
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
    }, 
    filterChanged(data) {
      this.filter = data
      this.getData()
    },
    getTime(item) {
      return getTime(item)
    }
  }
}
</script>