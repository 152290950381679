<template>
  <AppBreadcrumb
    :links="[{text:'Dashboard',url:'/admin'},{text:'Backoffice',url:'/admin/backoffice'},{text:'Add Backoffice Employee',url:''}]"
  />
  <AddUser
  userType="BACKOFFICE"
  />
</template>
<script>
import AddUser from '@/components/Admin/AddUser.vue'
import AppBreadcrumb from '@/components/AppBreadcrumb.vue'
export default {
  components:{
    AddUser,
    AppBreadcrumb
  }
}
</script>