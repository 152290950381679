<template>

        
  <section class="bg-white dark:bg-gray-900">
    <div class="px-4 mx-auto sm:w-full">
    <div class="">
  <ProductSelect
  v-if="showProductSelect"
  @productSelected="productSelected"
  @productRemoved="productRemoved"
  />
  </div>
    <div class="p-12" style="border-top: 3px dashed gray;border-bottom: 3px dashed gray;">
       <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 md:w-1/2">
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
          Invoice Duration (days)
        </label>
           <select v-model="invoiceDuration" id="category" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
            <option v-for="index in 30" :key="index" :value="index">{{index}}</option>
          </select>
      </div>
      <div class="mb-6 mt-6"><h3>Invoice Items</h3></div><hr class="mb-6"/>
      <div v-for="product in invoiceProducts" :key="product.id">
        <span style="color:lightseagreen"><u>{{product.product_name}}</u></span>
        <div class="flex flex-wrap -mx-3 mb-6 mt-4">
            <div class="w-full  px-3 mb-6 md:mb-0 md:w-1/3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                Product Price
              </label>
                <input type="text" v-model="product.price" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Price" disabled>
              
            </div>
            <div class="w-full  md:w-1/3 px-3" v-if="product.onetime">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                Units
              </label>
              <select :disabled="lead.status == 'contract_signed'" v-model="product.initialVal" id="category" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                 <option v-for="index in 2" :key="index" :value="index">{{index}}</option>
                </select>
            </div>
            <div class="w-full  md:w-1/3 px-3" v-if="product.per == 'Month'">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                Service Duration
              </label>
              <select :disabled="lead.status == 'contract_signed'" v-model="product.initialVal" id="category" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                <option value="12">12 Months</option>
                <option value="6">6 Months</option>
                </select>
            </div>
            <div class="w-full  md:w-1/3 px-3" v-if="product.per == 'Hour'">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                Service Hours
              </label>
                <input :disabled="lead.status == 'contract_signed'" v-model="product.initialVal" type="number"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Service Hours" required="">
            </div>
            <div class="w-full  md:w-1/3 px-3" v-if="product.per == 'Word'">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                Number Of Words
              </label>
                <input :disabled="lead.status == 'contract_signed'" v-model="product.initialVal" type="number"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Words" required="">
            </div>
            <div class="w-full  md:w-1/3 px-3" v-if="product.per == 'Comment'">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                Number Of Comments
              </label>
                <select :disabled="lead.status == 'contract_signed'" v-model="product.initialVal" id="category" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                <option v-for="index in 30" :key="index" :value="index">{{index}}</option>
                </select>
            </div>
            <div class="w-full  px-3 md:w-1/3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                Discount
              </label>
               <select :disabled="lead.status == 'contract_signed'" v-model="product.discount" id="category" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                <option v-for="discount in discounts" :key="discount" :value="discount.code">{{discount.name}}</option>
                </select>
              <!-- <input  type="number" v-model="product.discount" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Discount" required=""> -->
            </div>
        </div>
      </div>
      <div v-if="invoiceProducts.length == 0" class="mt-6 p-12" style="border:dashed 2px grey">No items added </div>
    </div>
    <div class="float-right mr-12">
      <div class="mt-6">Invoice Amount: {{netAmount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
      <button v-if="lead.status == 'contract_signed'" :disabled="invoiceURL" @click.prevent="sendInvoice" class=" mt-4 focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900">Send Invoice to {{lead.companyname}}</button>

      <button v-if="lead.status == 'in_progress'"  @click.prevent="sendQuotation" class=" mt-4 focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900">Send Quotation to {{lead.companyname}}</button>

       <a v-if="invoiceURL" :href="invoiceURL" target="_blank" class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400">
        <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            Review Invoice
        </span>
      </a>
      <a v-if="quoteURL" :href="quoteURL" target="_blank" class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400">
        <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            Review Quotation
        </span>
      </a>
     <!-- <p style="color:red" v-if="invoiceURL"> <a :href="invoiceURL" target="_blank">VIEW INVOICE</a></p> -->
    </div>
  </div>
  
</section>

</template>
<script>
/* eslint-disable */
//GszXSHYe
import { Modal } from 'flowbite-vue'
import ProductSelect from '@/components/Small/ProductSelect.vue'
import ENV from '@/env'
export default {
  components: {
    Modal,
    ProductSelect
  },
  props:['lead'],
  data() {
    return {
      showProductSelect:true,
      quoteURL:null,
      invoiceURL:null,
      allProducts:[],
      isShowModal:false,
      invoiceProducts:[],
      invoiceDuration:"10",
      discounts:[
        {
          name:"No Discount",
          code:"none",
          value:0
        },
        {
          name:"50% Discount",
          code:"dRZutBoe",
          value:50
        }
      ]
    }
  },
  async mounted() {
    try{
      if(this.lead.status == 'quote_send')  this.$swal.fire('Info!','Quotation is sent to the client','alert')
      if(this.lead.status == 'invoice_send') {
        this.$swal.fire('Warning','Invoice already send to this client','warning')
        this.$router.push(`/office/leadinfo/${this.lead.id}`)
      } 
      if(this.lead.status == 'invoice_paid')  this.$swal.fire('Warning','Invoice is paid by the customer','error')

      let { data } = await this.axios.get('public/products')
      this.allProducts = data
      let leadProducts = this.lead.services.split(",")
      // this.invoiceProducts = this.allProducts.filter(p => p.)
      // console.log(this.lead,'asdasdasdasdasdasdasd55')
      if(this.lead.quotationJSON) {
        let {data} = await this.axios.get(`${ENV.baseURL()}quotations/${this.lead.quotationJSON}`)
        this.invoiceProducts = data
        this.showProductSelect = false
      }
    }catch(e) {
      console.log('tere was an errr')
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: message });
    }
  },
  methods: {
    closeModal() {
      this.isShowModal = false
    },
    productSelected(product) {
      this.invoiceProducts.push(product)
    },
    productRemoved(product) {
      this.invoiceProducts = this.invoiceProducts.filter(p => p.id != product.id)
    },
    async sendQuotation() {
        let servicesData = this.invoiceProducts.map((service)=>{
          let price = service.price
          let discountVal = this.discounts.find(d => d.code == service.discount).value
          let discountApplied = (discountVal/100) * price
          price = price - discountApplied
          
        return {
          serviceId :service.id,
          unit_amount:price ,
          service_units: Number(service.initialVal)
        }
      })
      let postObject = {
        leadId:this.$route.params.id,
        prices:servicesData,
        invoiceProducts:JSON.stringify(this.invoiceProducts)
      }
      try{
        let { data } = await this.axios.post(`backoffice/create-stripe-quote`,postObject)
        // console.log(data.invoice_pdf)
        // this.invoiceURL = data.invoice_pdf
        this.$swal.fire(
          'Success',
          'Quotation Send Successfully!',
          'success'
        )
        console.log(`quotations/${data.pdf}.pdf`)
        this.quoteURL = `${ENV.baseURL()}quotations/${data.pdf}.pdf`
        this.$router.push(`/office/leadinfo/${this.lead.id}`)
        // window.open(data.invoice_pdf);
        // this.data = data.data
        // this.$notify({ title:"Message",type: "success", text: "User added successfully" });
      }catch(e) {
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
      console.log(servicesData)
    },
    async sendInvoice() {
      let servicesData = this.invoiceProducts.map((service)=>{
        return {
          serviceId :service.id,
          discount_applied:service.discount ? service.discount : 0 ,
          service_units: Number(service.initialVal)
        }
      })
      let postObject = {
        leadId: this.$route.params.id,
        days_until_due : Number(this.invoiceDuration),
        prices:servicesData
      }
      console.log(servicesData)
      try{
        let { data } = await this.axios.post(`backoffice/create-stripe-invoice`,postObject)
        console.log(data.invoice_pdf)
        this.invoiceURL = data.invoice_pdf
        this.$swal.fire(
          'Success',
          'Invoice Send Successfully!',
          'success'
        )
          this.$router.push(`/office/leadinfo/${this.lead.id}`)
        // window.open(data.invoice_pdf);
        // this.data = data.data
        // this.$notify({ title:"Message",type: "success", text: "User added successfully" });
      }catch(e) {
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
    }
  },
  computed: {
    netAmount:function() {
      return this.invoiceProducts.reduce((acc,currentProduct) => {
        try {
          if(currentProduct.discount > 80 || currentProduct.discount < 0 ) throw Error('disc_error')
          if(currentProduct.initialVal < 0) throw Error('val_error')

              let appliedDiscount = this.discounts.find(d => d.code == currentProduct.discount).value
              acc = acc + (currentProduct.price * Number(currentProduct.initialVal))
          
          let discount = (appliedDiscount/100) * (currentProduct.price * Number(currentProduct.initialVal))
          return acc -discount 
        }catch(e) {
          console.log(e.message)
          if(e.message == 'disc_error') currentProduct.discount = 0; this.$notify({ title:"Message",type: "error", text: "Discount exceed" })  
          if(e.message == 'val_error')   currentProduct.initialVal = 1; this.$notify({ title:"Message",type: "error", text: "Invalid Value" }) 
        }
      },0)
    }
  }
}
</script>
