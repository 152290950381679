<template>
  <div class="mb-12" v-if="data">
    <a href="#">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{data.companyname}}</h5>
      </a>
      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Client Name: {{data.fullname}}</p>
      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Email: {{data.email}}</p>
      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Phonenumber: {{data.phonenumber}}</p>
      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Skype: {{data.skype}}</p>
      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Country: {{data.country}}</p>
      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">State: {{data.state}}</p>
      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">City: {{data.city}}</p>
      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">ZipCode: {{data.zipCode}}</p>

      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Address: {{data.address}}</p>
      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Services: {{data.services}}</p>
      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Comments: {{data.comments}}</p>
    </div>
</template>
<script>
export default {
  props:['data']
}
</script>
