import { createApp } from 'vue'
import App from './App.vue'
import './style.css'
import router from './router'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Notifications from '@kyvg/vue3-notification'
import store from './store'
import VueAxios from 'vue-axios'
import axios from './axios'

createApp(App)
  .use(router)
  .component('EasyDataTable', Vue3EasyDataTable)
  .use(VueSweetalert2)
  .use(Notifications)
  .use(VueAxios, axios)
  .use(store)
  .mount('#app')
