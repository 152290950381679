<template>
<ol class="relative border-l border-gray-200 dark:border-gray-700">                  
    <li v-for="comment in  comments" :key="comment.id" class="mb-10 ml-6">
      <div v-if="comment.commentType == 'admin_simple_comment'">

        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <img class="rounded-full shadow-lg" :src="getSrc(comment.commenter)" alt="Thomas Lean image"/>
        </span>
        <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
            <div class="items-center justify-between mb-3 sm:flex">
                <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{getTime(comment)}}</time>
                <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">
                  <span style="color:black" class="bg-blue-300 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">{{comment.commenter.fullname}}</span>
                  commented
                </div>
            </div>
            <div class="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">
                  <!-- <span class="bg-gray-200 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">Dialogmakers International</span><br><br> -->
    
              {{comment.comment}}</div>
        </div>
      </div>
      <div v-if="comment.commentType == 'backoffice_simple_comment'">
        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <img class="rounded-full shadow-lg" :src="getSrc(comment.commenter)" alt="Thomas Lean image"/>
        </span>
        <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
            <div class="items-center justify-between mb-3 sm:flex">
                <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{getTime(comment)}}</time>
                <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">{{comment.commenter.username}} commented</div>
            </div>
            <div class="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">{{comment.comment}}</div>
        </div>
      </div>
       
      
      <div v-if="comment.commentType == 'lead_declined'">
        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <img class="rounded-full shadow-lg" :src="getSrc(comment.commenter)" alt="Thomas Lean image"/>
        </span>
        <div class=" p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
            <div class="items-center justify-between sm:flex">
                <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{getTime(comment)}}</time>
                <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">{{comment.commenter.username}} <b>declined this lead</b></div>
            </div>
        </div>
      </div> 
      <div v-if="comment.commentType == 'reschedule_comment'">
         <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <img class="rounded-full shadow-lg" :src="getSrc(comment.commenter)" alt="Jese Leos image"/>
        </span>
        <div class="items-center justify-between p-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:flex dark:bg-gray-700 dark:border-gray-600">
            <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{getTime(comment)}}</time>
            <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">{{comment.commenter.username}} has <a href="#" class="font-semibold text-blue-600 dark:text-blue-500 hover:underline">Scheduled</a> this lead to <span class="font-semibold text-gray-900 dark:text-white">{{new Date(lead.rescheduled)}}</span>
            <br><br>
            <div class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300" role="alert">
              <span class="font-medium">Schedule Reason !</span> <br><br> {{comment.comment}}
            </div>
            </div>
        </div>
      </div>  
      <div v-if="comment.commentType == 'unschedule_comment'">
        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <img class="rounded-full shadow-lg" :src="getSrc(comment.commenter)" alt="Thomas Lean image"/>
        </span>
        <div class=" p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
            <div class="items-center justify-between sm:flex">
                <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{getTime(comment)}}</time>
                <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">{{comment.commenter.username}} <b>Unscheduled this lead</b></div>
            </div>
        </div>
      </div>
      <div v-if="comment.commentType == 'payment_released'">

        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <img class="rounded-full shadow-lg" :src="getSrc(comment.commenter)" alt="Thomas Lean image"/>
        </span>
        <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
            <div class="items-center justify-between mb-3 sm:flex">
                <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{getTime(comment)}}</time>
                <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">
                  <span style="color:black" class="bg-blue-300 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">{{comment.commenter.fullname}}</span>
                  
                </div>
            </div>
            <div class="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">
                  <!-- <span class="bg-gray-200 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">Dialogmakers International</span><br><br> -->
    
              {{comment.comment}}</div>
        </div>
      </div>  
       <div v-if="comment.commentType == 'invoice_paid'">

        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <img class="rounded-full shadow-lg" src="@/assets/images/tick.jpg" alt="Thomas Lean image"/>
        </span>
        <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
            <div class="items-center justify-between mb-3 sm:flex">
                <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{getTime(comment)}}</time>
                <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">
                  <span style="color:black" class="bg-blue-300 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">Invoice Notification</span>
                  
                </div>
            </div>
            <div class="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">
                  <!-- <span class="bg-gray-200 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">Dialogmakers International</span><br><br> -->
    
              {{comment.comment}}</div>
        </div>
      </div>
      <div v-if="comment.commentType == 'invoice_send'">
         <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <img class="rounded-full shadow-lg" src="@/assets/images/tick.jpg" alt="Thomas Lean image"/>
        </span>
        <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
            <div class="items-center justify-between mb-3 sm:flex">
                <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{getTime(comment)}}</time>
                <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">
                  <span style="color:black" class="bg-blue-300 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">Invoice Notification</span>
                  
                </div>
            </div>
            <div class="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">
                  <!-- <span class="bg-gray-200 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">Dialogmakers International</span><br><br> -->
    
              {{comment.comment}} by <b><u>{{comment.commenter.username}}</u></b>
              &nbsp; <a class="bg-yellow-200 p-2" target="_blank" :href="lead.invoicepdf">View Invoice</a>
              </div>
        </div>
      </div>
      <div v-if="comment.commentType == 'quote_send'">
         <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <img class="rounded-full shadow-lg" src="@/assets/images/tick.jpg" alt="Thomas Lean image"/>
        </span>
        <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
            <div class="items-center justify-between mb-3 sm:flex">
                <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{getTime(comment)}}</time>
                <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">
                  <span style="color:black" class="bg-blue-300 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">Quotation Notification</span>
                  
                </div>
            </div>
            <div class="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">
                  <!-- <span class="bg-gray-200 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">Dialogmakers International</span><br><br> -->
    
              {{comment.comment}} by <b><u>{{comment.commenter.username}}</u></b>
              &nbsp; <a class="bg-pink-200 p-2" target="_blank" :href="`${baseURL()}quotations/${lead.quotationpdf}.pdf`">View Quotation</a>
              </div>
        </div>
      </div>
      <div v-if="comment.commentType == 'quote_accepted'">
         <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <img class="rounded-full shadow-lg" src="@/assets/images/tick.jpg" alt="Thomas Lean image"/>
        </span>
        <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
            <div class="items-center justify-between mb-3 sm:flex">
                <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{getTime(comment)}}</time>
                <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">
                  <span style="color:black" class="bg-blue-300 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">Quotation Notification</span>
                  
                </div>
            </div>
            <div class="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">
                  <!-- <span class="bg-gray-200 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">Dialogmakers International</span><br><br> -->
    
              {{comment.comment}}
              &nbsp; 
              </div>
        </div>
      </div>
      <div v-if="comment.commentType == 'contract_send'">
        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
             <img class="rounded-full shadow-lg" src="@/assets/images/tick.jpg" alt="Thomas Lean image"/>
        </span>
        <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
            <div class="items-center justify-between mb-3 sm:flex">
                <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{getTime(comment)}}</time>
                 <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">
                  <span style="color:black" class="bg-blue-300 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">Contract Send Notification</span>
                </div>
            </div>
            <div class="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">{{comment.comment}}</div>
        </div>
      </div>
      <div v-if="comment.commentType == 'contract_signed'">
        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
             <img class="rounded-full shadow-lg" src="@/assets/images/tick.jpg" alt="Thomas Lean image"/>
        </span>
        <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
            <div class="items-center justify-between mb-3 sm:flex">
                <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{getTime(comment)}}</time>
                 <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">
                  <span style="color:black" class="bg-blue-300 text-black-900 text-md font-normal mr-2 px-2.5 py-0.5 rounded d">Contract Signed Notification</span>
                </div>
            </div>
            <div class="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">{{comment.comment}}</div>
        </div>
      </div>
        
    </li>
    <!-- <li class="mb-10 ml-6">
        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <img class="rounded-full shadow-lg" src="https://flowbite.com/docs/images/people/profile-picture-1.jpg" alt="Thomas Lean image"/>
        </span>
        <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
            <div class="items-center justify-between mb-3 sm:flex">
                <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">2 hours ago</time>
                <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">Thomas Lean commented on  <a href="#" class="font-semibold text-gray-900 dark:text-white hover:underline">Flowbite Pro</a></div>
            </div>
            <div class="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">Hi ya'll! I wanted to share a webinar zeroheight is having regarding how to best measure your design system! This is the second session of our new webinar series on #DesignSystems discussions where we'll be speaking about Measurement.</div>
        </div>
    </li>
    <li class="mb-10 ml-6">
        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <img class="rounded-full shadow-lg" src="https://flowbite.com/docs/images/people/profile-picture-1.jpg" alt="Jese Leos image"/>
        </span>
        <div class="items-center justify-between p-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:flex dark:bg-gray-700 dark:border-gray-600">
            <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">1 day ago</time>
            <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">Jese Leos has changed <a href="#" class="font-semibold text-blue-600 dark:text-blue-500 hover:underline">Pricing page</a> task status to  <span class="font-semibold text-gray-900 dark:text-white">Finished</span></div>
        </div>
    </li> -->
</ol>
    <div v-if="$store.state.core.userData.role != 'HUNTER'" class="mt-8 mb-10 ml-6 w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
       <div class="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
           <label for="comment" class="sr-only">Your comment</label>
           <textarea v-model="comment" id="comment" rows="4" class="w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="Write a comment..."></textarea>
       </div>
       <div class="flex items-center justify-between px-3 py-2 border-t dark:border-gray-600">
           <button @click.prevent="postComment" class="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800">
               Post comment
           </button>
       </div>
   </div>
</template>

<script>
/* eslint-disable */
//post-comment-lead/:id
import { getTime } from '@/utils/helpers'
import helperMixin from '@/mixins/helpers'
import ENV from '@/env'
export default  {
  mixins:[helperMixin],
  props:['lead'],
  async mounted() {
    this.comments = this.lead.comment
    //  try{
    //   let { data } = await this.axios.get(`backoffice/lead-comments/${this.$route.params.id}`)
    //   // this.data = data.data
    //   // this.$notify({ title:"Message",type: "success", text: "User added successfully" });
    // }catch(e) {
    //   console.log('tere was an errr')
    //   let { data : { message } } = e.response
    //   this.error = message
    //   this.$notify({ title: "Error", text: message });
    // }
  },
  data() {
    return {
      comment: "",
      comments:[]
    }
  },
  computed: {
    apiLink:function() {
      if(this.$store.state.core.adminloggedIn) return 'admin'
      if(this.$store.state.core.backofficeLoggedIn) return 'backoffice'
      // if(this.$store.state.core.hunterLoggedIn) return '/hunter'
      return '/'
    }
  },
  methods: { 
    async postComment(){ 
      try{
        let { data } = await this.axios.post(`${this.apiLink}/post-comment-lead/${this.lead.id}`,{
          comment:this.comment
        })
        this.$swal.fire(
          'Success',
          'Comment Posted',
          'success'
        )
        this.comment = ''
        this.comments.push(data.data)
        console.log(this.comments)
        // this.$router.push('/office/myleads')

        // this.$notify({ title:"Message",type: "success", text: "Lead Grabbed Successfully" });
      }catch(e) {
        console.log(e)
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
    },
    getTime(item) {
      return getTime(item)
    },
    baseURL() {
      return ENV.baseURL()
    }
  }
}
</script>
