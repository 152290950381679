import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const core = {
  state: {
    userData: {},
    backofficeLoggedIn: false,
    adminloggedIn: false,
    hunterLoggedIn:false,
    token: null,
    loading: false,
    leadsFilter: {
      in_progress:true,
      scheduled:true,
      declined:true,
      payment_received:true,
      contract_send:true,
      contract_signed:true,
      invoice_paid: true,
      quote_send:true,
      invoice_send:true,
      quote_accepted:true
    }
  },
  mutations: {
    leadsFilter(state, data) {
      state.leadsFilter = data;
    },
    userData(state, data) {
      state.userData = data;
    },
    backofficeLoggedIn(state, data) {
      state.backofficeLoggedIn = data;
    },
    hunterLoggedIn(state, data) {
      state.hunterLoggedIn = data;
    },
    adminloggedIn(state, data) {
      state.adminloggedIn = data;
    },
    token(state, data) {
      state.token = data;
    },
    loading(state, data) {
      state.loading = data;
    }
  },
  actions: {},
  getters: {
    userData(state) {
      return state.userData;
    },
    leadsFilter(state) {
      return state.leadsFilter;
    },
    userAccounts(state) {
      return state.userAccounts;
    },
    backofficeLoggedIn(state) {
      return state.backofficeLoggedIn;
    },
    adminloggedIn(state) {
      return state.adminloggedIn;
    },
    hunterLoggedIn(state) {
      return state.hunterLoggedIn;
    },
    token(state) {
      return state.token;
    },
    loading(state) {
      return state.loading;
    },
  },
};

const store = createStore({
  modules: {
    core: core,
  },
  plugins: [
    createPersistedState({
      paths: ["core.userData","core.backofficeLoggedIn","core.adminloggedIn","core.hunterLoggedIn","core.token","core.loading","core.leadsFilter"],
    }),
  ],
});

export default store;
