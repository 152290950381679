<template>
  <div>
     <AppBreadcrumb
      :links="[{text:'Dashboard',url:'/admin'},{text:'Head Hunters',url:''}]"
    />
    <router-link to="hunter/add" type="button" class="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
  <img style="width:20px" class="mr-2" src="@/assets/images/fox.jpg">
  Add Head Hunter
</router-link>
     <EasyDataTable
        buttons-pagination
          border-cell
          :alternating="true"
          theme-color="f"
          rows-per-page="20"
          :headers="headers"
          :items="items"
          search-field="email"
          :search-value="searchValue"
        >
         <template #item-operation="">
          <div class="operation-wrapper">

           <Icon icon="zondicons:align-justified" height="24" :inline="true" />
          </div>
        </template>
        <template #item-active="item">
          <label class="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" @click.prevent="toggleUserStatus($event,item)"  class="sr-only peer" :checked="item.active">
              <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
        </template>
        </EasyDataTable>
  </div>
</template>
<script>
/* eslint-disable */
//<Icon icon="ic:outline-warning" height="24" :inline="true" />
//https://icon-sets.iconify.design/zondicons/align-justified/
import { Icon } from '@iconify/vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue'

export default {
  components:{
    Icon,
    AppBreadcrumb
  },
  data(){
    return {
      loading:false,
      searchValue:"",
      headers:[
        { text: "Username", value: "username"  },
        { text: "Fullname", value: "fullname"  },
        { text: "Operation", value: "operation" },
        { text: "Status", value: "active" }

      ],
       items : []
    }
  },
  async mounted(){ 
    try{
      let { data } = await this.axios.get('admin/get-users?type=HUNTER')
      this.items = data.data
      // this.$notify({ title:"Message",type: "success", text: "User added successfully" });
    }catch(e) {
      console.log('tere was an errr')
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: message });
    }
  },
  methods: {
    async toggleUserStatus(event,user) {
      console.log(event)
      console.log(user)
      try{
        let { data } = await this.axios.put(`admin/toggle-user-status/${user.id}`)
        this.items = this.items.map((item)=>{
          if(item.id == user.id) {
            item.active = !item.active
            return item
          }else {
            return item
          }
        })
        // this.$notify({ title:"Message",type: "success", text: "User added successfully" });
      }catch(e) {
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: message });
      }
    }
  }
}
</script>