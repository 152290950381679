<template>
  <!-- component -->
<div class="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
	<div class="relative py-3 sm:max-w-xl sm:mx-auto">
		<div
			class="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
		</div>
		<div class="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
			<div class="max-w-md mx-auto">
				<div>
					<h1 class="text-2xl font-semibold">Dialogmakers International Panel</h1>
				</div>
				<div class="divide-y divide-gray-200">
					<div class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
						<div class="relative">
							<input v-model="username" autocomplete="off"  name="em" type="text" class="mt-2 peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Email address" />
							<div class="input-errors" v-for="error of v$.username.$errors" :key="error.$uid">
								<span v-if="v$.username.$error" class="form-error">{{ error.$message }}</span>
						</div>
							<label class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Username</label>
						</div>
						<div class="relative">
							<input v-model="password" autocomplete="off" id="password" name="password" type="password" class="mt-2 peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Password" />
							<div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
								<span v-if="v$.password.$error" class="form-error">{{ error.$message }}</span>
						</div>
							<label for="password" class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Password</label>
						</div>
						<div class="relative">
							<button @click.prevent="login" class="bg-blue-500 text-white rounded-md px-2 py-1">Login</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script>
/* eslint-disable */
import { useVuelidate } from '@vuelidate/core'
import { required , minLength } from '@vuelidate/validators'
import HelperMixin from '@/mixins/helpers'

export default  {
	setup () {
    return { v$: useVuelidate() }
  },
	validations () {
    return {
      username: { required },
      password: { required , minLength:minLength(3) },
    }
  },
  data() {
    return {
      username:"",
      password:""
    }
  },
	mixins:[HelperMixin],
	mounted() {
		if(this.$store.state.core.adminloggedIn) {
			this.$router.push('/admin')
		}
		if(this.$store.state.core.backofficeLoggedIn) {
			// this.$router.push('/admin')
		}
		if(this.$store.state.core.hunterLoggedIn) {
			// this.$router.push('/admin')
		}
	},
  methods: {
    async login() {
			const isFormCorrect = await this.v$.$validate()
			if(!isFormCorrect) return 
			try{
        let { data } = await this.axios.post('user/login',{
          username:this.username,
          password:this.password
        })
        this.$notify({ title:"Message",type: "success", text: "Loggedin Successfully" });
        // console.log(data)
        this.logOutUser()
        this.$store.commit("userData",data.user)
				this.$store.commit("token",data.token)
				if(data.user.role == 'ADMIN') {
					this.$store.commit("adminloggedIn",true)
					this.$router.push('/admin')
				} else if(data.user.role == 'BACKOFFICE') {
					this.$store.commit("backofficeLoggedIn",true)
					this.$router.push('/office')
				} else if(data.user.role == 'HUNTER') {
					this.$store.commit("hunterLoggedIn",true)
					this.$router.push('/hunter')
				}
        // this.$store.commit("userAccounts",data.accounts)
        // this.$store.commit("activeAccount",data.activeAccount)
        // this.$store.commit("token",data.token)
        // this.$store.commit("adminloggedIn",false)
        // 
        // this.$router.push('/dashboard')
      }catch(e) {
        console.log('tere was an errr')
        let { data : { message } } = e.response
        this.error = message
				this.$notify({ title: "Error", text: message });
      }
    }
  }
}
</script>
