<template>
<div class="flex items-center text-lg">
          Select Products
        </div>
  <div class="grid lg:grid-cols-6 sm:grid-cols-1 gap-4 mb-8 mt-4" style="height:500px;overflow-y:scroll;overflow-x:hidden">
    <div v-for="product in allProducts" :key="product.id" class="flex items-center justify-center rounded dark:bg-gray-800">
      <a :class="{'product-selected':selectedProducts.find(p => p.id==product.id)}" href="#" @click.prevent="selectProduct(product)">
        <div class="rounded overflow-hidden shadow-lg">
         <img class="w-full"  :src="`https://storage.googleapis.com${product.image}`" alt="Sunset in the mountains">
        <div class="px-6 py-4">
          <div class="font-bold  mb-2">{{ product.product_name}}</div>
        </div>
        <div class="px-6 pt-4 pb-2">
          <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          Price: {{product.price}} USD {{product.onetime ? '' : 'per '+product.per}}
        </span>
         <span v-if="selectedProducts.find(p => p.id == product.id)" @click.stop="removeProduct(product)" class="inline-block bg-red-200  px-3 py-1 text-sm  text-red-700 mr-2 mb-2">
           Remove Product
        </span>
        <!-- <span v-if="product.discount_percent" class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Discount% {{product.discount_percent}}</span>
        <span v-if="product.discount_price" class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Discount Price: {{product.discount_price}}</span> -->
        </div>
      </div>
      
      </a>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default  {
  data() {
    return {
      allProducts: [],
      selectedProducts: []
    }
  },
  async mounted() {
    try{
      let { data } = await this.axios.get('public/products')
      this.allProducts = data
    }catch(e) {
      console.log('tere was an errr')
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: message });
    }
  },
  methods: {
    selectProduct(product) {
      if(this.selectedProducts.findIndex(p => p.id == product.id) == -1) {
        product.discount = 'none'
        switch(product.per) {
          case 'Month':
              product.initialVal = '6'
            break;
          default:
            product.initialVal = '1'
        }
        this.selectedProducts.push(product)
        this.$emit('productSelected',product)
      }
    },
    removeProduct(product) {
      this.selectedProducts = this.selectedProducts.filter(p => p.id != product.id)
      this.$emit('productRemoved',product)
    }
  }
}
</script>
<style scoped>
.product-selected {
  border: solid 2px lightseagreen;
  color:lightseagreen;
}
</style>