import { createWebHistory, createRouter } from 'vue-router'


//Common
import AppLogin from '@/views/AppLogin.vue'
import AcceptQuote from '@/views/AcceptQuote.vue'
import UserProfile from '@/views/UserProfile.vue'


//Admin
import AdminIndex from '@/views/Admin/AdminIndex.vue'
import AdminDashboard from '@/views/Admin/AdminDashboard.vue'
import BackofficeUsers from '@/views/Admin/BackofficeUsers.vue'
import HunterUsers from '@/views/Admin/HunterUsers.vue'

import AddBackoffice from '@/views/Admin/AddBackoffice.vue'
import AddHunter from '@/views/Admin/AddHunter.vue'
import AllLeads from '@/views/Admin/AllLeads.vue'
import EditLead from '@/views/Admin/EditLead.vue'
import DeclinedLeads from '@/views/Admin/DeclinedLeads.vue'
import ScheduledLeads from '@/views/Admin/ScheduledLeads.vue'
import PaidLeads from '@/views/Admin/PaidLeads.vue'







// Hunter
import HunterIndex from '@/views/Hunter/HunterIndex.vue'
import HunterDashboard from '@/views/Hunter/HunterDashboard.vue'
import AddLead from '@/views/Hunter/AddLead.vue'
import HunterLeads from '@/views/Hunter/HunterLeads.vue'
import LeadDetails from '@/views/Hunter/LeadInfo.vue'




// office
import OfficeIndex from '@/views/Backoffice/OfficeIndex.vue'
import OfficeDashboard from '@/views/Backoffice/OfficeDashboard.vue'
import AllLeadsoffcie from '@/views/Backoffice/AllLeads.vue'
import LeadInfo from '@/views/Backoffice/LeadInfo.vue'
import MyLeads from '@/views/Backoffice/MyLeads.vue'
import SendInvoice from '@/views/Backoffice/SendInvoice.vue';









import { adminAuthGuard , hunterAuthGuard,officeAuthGuard ,loggedInGuard } from '../utils/helpers'


const routes = [
  { path: '/', component: AppLogin ,name:'AppLogin'},
  { path: '/accept-quote/:id', component: AcceptQuote ,name:'AcceptQuote'},

  { path: '/profile', component: UserProfile ,name:'UserProfile',
    beforeEnter: (to, from, next) => {
      loggedInGuard(to, from, next)
    }},

  { path: '/admin', component: AdminIndex ,name:'AdminIndex',
  beforeEnter: (to, from, next) => {
    adminAuthGuard(to, from, next)
  },
    children:[
      { path: '', component: AdminDashboard,name:"AdminDashboard" },
      { path: 'backoffice', component: BackofficeUsers,name:"BackofficeUsers" },
      { path: 'hunter', component: HunterUsers,name:"HunterUsers" },

      { path: 'backoffice/add', component: AddBackoffice,name:"AddBackoffice" },
      { path: 'hunter/add', component: AddHunter,name:"AddHunter" },
      { path: 'leads', component: AllLeads,name:"AllLeads" },
      { path: 'edit-lead/:id', component: EditLead,name:"EditLead" },
      { path: 'declined-leads', component: DeclinedLeads,name:"DeclinedLeads" },
      { path: 'scheduled-leads', component: ScheduledLeads,name:"ScheduledLeads" },
      { path: 'paid-leads', component: PaidLeads,name:"PaidLeads" },


      


    ]
  },
  { path: '/hunter', component: HunterIndex ,name:'HunterIndex',
  beforeEnter: (to, from, next) => {
    hunterAuthGuard(to, from, next)
  },
    children:[
      { path: '', component: HunterDashboard,name:"HunterDashboard" },
      { path: 'add-lead', component: AddLead,name:"AddLead" },
      { path: 'leads', component: HunterLeads,name:"HunterLeads" },
      { path: 'leadinfo/:id', component: LeadDetails,name:"LeadDetails" },


    ]
  },
  { path: '/office', component: OfficeIndex ,name:'OfficeIndex',
  beforeEnter: (to, from, next) => {
    officeAuthGuard(to, from, next)
  },
    children:[
      { path: '', component: OfficeDashboard,name:"OfficeDashboard" },
      { path: 'leads', component: AllLeadsoffcie,name:"AllLeadsoffcie" },
      { path: 'leadinfo/:id', component: LeadInfo,name:"LeadInfo" },
      { path: 'myleads', component: MyLeads,name:"MyLeads" },
      { path: 'send-invoice/:id', component: SendInvoice,name:"SendInvoice" },
    ]
  },
]




const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router


// { 
//   path: '/admin', 
//   component: MazuAdmin,
//   name:"admin", 
//   beforeEnter: (to, from, next) => {
//     adminAuthGuard(to, from, next)
//   },
//   children:[
//     { path: '', component: AdminHome,name:"adminHome" },
//     { path: 'system', component: MazuSystem,name:"MazuSystem" },

    
    


//     { path: 'extensions', component: AdminExtensions,name:"adminExtensions" },
//     { path: 'extensions/add', component: AddExtension,name:"addExtensions" },
//     { path: 'extensions/edit/:id', component: UpdateExtension,name:"updateExtensions" },
//     { path: 'extensions/gallery/:id', component: ExtensionGallery,name:"extensionGallery" },
//     { path: 'extensions/prices/:id', component: ExtensionPrices,name:"extensionPrices" },


//     { path: 'themes', component: AdminThemes,name:"adminThemes" },
//     { path: 'themes/add', component: AddTheme,name:"addThemes" },
//     { path: 'themes/edit/:id', component: UpdateTheme,name:"updateThemes" },
//     { path: 'themes/gallery/:id', component: ThemeGallery,name:"themeGallery" },
//     { path: 'themes/prices/:id', component: ThemePrices,name:"themePrices" },


//     { path: 'plugins', component: AdminPlugins,name:"adminPlugins" },
//     { path: 'plugins/add', component: AddPlugin,name:"addPlugins" },
//     { path: 'plugins/edit/:id', component: UpdatePlugin,name:"updatePlugins" },
//     { path: 'plugins/gallery/:id', component: PluginGallery,name:"pluginGallery" },
//     { path: 'plugins/prices/:id', component: PluginPrices,name:"pluginPrices" },


//     { path: 'mazus', component: AdminMazus,name:"adminMazus" },
//     { path: 'mazus/add', component: AddMazu,name:"addMazus" },
//     { path: 'mazus/edit/:id', component: UpdateMazu,name:"updateMazus" },
//     { path: 'mazus/gallery/:id', component: MazuGallery,name:"mazuGallery" },
//     { path: 'mazus/content/:id', component: MazuContent,name:"mazuContent" },

//     { path: 'mazus/prices/:id', component: MazuPrices,name:"mazuPrices" },


//     { path: 'mazu-content/add', component: AddMazuContent,name:"AddMazuContent" },
//     { path: 'mazu-content', component: AdminMazuContent,name:"AdminMazuContent" },
//     { path: 'mazu-content/edit/:id', component: UpdateContent,name:"UpdateContent" },

//     { path: 'dating-communities', component: DatingCommunities,name:"DatingCommunities" },
//     { path: 'dating-community/:id', component: DatingCommunitysetting,name:"DatingCommunitysetting" },
//     { path: 'dating-community/update-db/:id', component: UpdateDburl,name:"UpdateDburl" },

    



//      { path: 'users', component: AllUsers,name:"allUsers" },
//      { path: 'admins', component: MazuAdmins,name:"MazuAdmins" },
//      { path: 'admins/add', component: AddAdmin,name:"AddAdmin" },

     
     

     





//   ]
// },